// import axios from "axios";
// import { useRef, useState } from "react";
// import GoogleIcon from "@mui/icons-material/Google";
// import PrimaryButton from "../buttons/PrimaryButton";
import { Box, Button } from "@mui/material";
// import { useEffect } from "react";
import jwt from "jwt-decode";
import { Auth } from "aws-amplify";
// import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";
import {
	// useGoogleLogin,
	// TokenResponse,
	// googleLogout,
	GoogleLogin,
	GoogleOAuthProvider,
} from "@react-oauth/google";
// import GoogleButton from "react-google-button";
// import axios from "axios";

const SocialLogin = ({ label }: { label?: string }) => {
	const { handleSocialLogin } = useAuth();
	const { showSnackbar } = useAlert();
	// const [googleLoaded, setGoogleLoaded] = useState<boolean>(false);
	// const googleBtn = useRef<HTMLButtonElement>(null);
	const getUser = async (token: any): Promise<void> => {
		try {
			const currentUser = await Auth.currentAuthenticatedUser();
			// console.log("currentUser", currentUser);
			// setUser(currentUser);
			await handleSocialLogin(token);
		} catch (error) {
			console.error(error);
			console.log("Not signed in");
		}
	};

	// const googleLogin = useGoogleLogin({
	// 	onSuccess: async (tokenResponse) => {
	// 		console.log("tokenResponse", tokenResponse);
	// 		const token = jwt(
	// 			tokenResponse.access_token as unknown as string
	// 		) as any;
	// 		console.log("getAWSCredentials token", token);
	// 		const userInfo = await axios
	// 			.get("https://www.googleapis.com/oauth2/v3/userinfo", {
	// 				headers: {
	// 					Authorization: `Bearer ${tokenResponse.access_token}`,
	// 				},
	// 			})
	// 			.then((res) => res.data);
	// 		console.log("userInfo", userInfo);
	// 	},
	// 	onError: (errorResponse) => console.log(errorResponse),
	// });

	// useEffect(() => {
	// 	// Check for an existing Google client initialization

	// 	console.log("googleLoaded", googleLoaded);
	// 	console.log("googleBtn", googleBtn.current);
	// 	//@ts-ignore
	// 	if (!window?.google?.accounts && !googleLoaded && googleBtn.current)
	// 		createScript();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// 	// return () => {
	// 	// 	// Cleanup
	// 	// 	const script = document.getElementById("googleSignInButtonScript");
	// 	// 	if (script) {
	// 	// 		script.remove();
	// 	// 	}
	// 	// 	//@ts-ignore
	// 	// 	if (window.google?.accounts) {
	// 	// 		//@ts-ignore
	// 	// 		window.google.accounts.id.cancel();
	// 	// 	}
	// 	// };
	// }, [googleBtn]);

	// // Load the Google client
	// const createScript = () => {
	// 	const script = document.createElement("script");
	// 	script.id = "googleSignInButtonScript";
	// 	script.src = "https://accounts.google.com/gsi/client";
	// 	script.async = true;
	// 	script.defer = true;
	// 	script.onload = initGsi;
	// 	document.body.appendChild(script);
	// 	console.log("script", script);
	// };

	// // Initialize Google client and render Google button
	// const initGsi = () => {
	// 	console.log("initGsi");
	// 	//@ts-ignore
	// 	if (window.google?.accounts) {
	// 		//@ts-ignore
	// 		window.google.accounts.id.initialize({
	// 			client_id:
	// 				"832108443867-fu9hmp1hbqqpdp8eb8p3h327n1683vka.apps.googleusercontent.com",
	// 			callback: (response: any) => {
	// 				getAWSCredentials(response.credential);
	// 			},
	// 		});
	// 		//@ts-ignore
	// 		window.google.accounts.id.renderButton(
	// 			// document.getElementById("googleSignInButton"),
	// 			googleBtn.current,
	// 			{ theme: "outline", size: "large" }
	// 		);
	// 		setGoogleLoaded(true);
	// 	}
	// };

	// // Exchange Google token for temporary AWS credentials
	const getAWSCredentials = async (credential: string) => {
		try {
			const token = jwt(credential) as any;
			// console.log("getAWSCredentials token", token);
			const user = {
				email: token.email,
				name: token.name,
			};
			const loggedInUser = await Auth.federatedSignIn(
				"google",
				{ token: credential, expires_at: token.exp },
				user
			);
			// console.log("loggedInUser", loggedInUser);
			if (loggedInUser) {
				getUser(token);
				// handleSocialLogin(user);
			}
		} catch (error: any) {
			console.log("getAWSCredentials error", error);
			showSnackbar(error?.message, error);
		}
	};

	return (
		// <GoogleOAuthProvider clientId="1091870043456-6rff2h4s7jn0i6iic935ok8fpf03n2as.apps.googleusercontent.com">
		<GoogleOAuthProvider clientId="832108443867-fu9hmp1hbqqpdp8eb8p3h327n1683vka.apps.googleusercontent.com">
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					gap: "12px",
				}}
			>
				{/* <Button id="googleSignInButton" ref={googleBtn}>
				{label ? label : "Sign in with Google"}
			</Button> */}
				<GoogleLogin
					onSuccess={(credentialResponse) => {
						// console.log(credentialResponse);
						if (credentialResponse?.credential)
							getAWSCredentials(credentialResponse.credential);
					}}
					onError={() => {
						console.log("Login Failed");
					}}
				/>

				{/* <GoogleButton
				type="light" // can be light or dark
				onClick={() => {
					googleLogin();
				}}
			/> */}
			</Box>
		</GoogleOAuthProvider>
	);
};

export default SocialLogin;
